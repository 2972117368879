import Terminal from './terminal';


export class App {
    constructor() {
		const terminal = new Terminal('.terminal');
    }
}

new App();
